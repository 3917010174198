<template>
    <section class="mt-5">
        <div class="container mt-5 ">
            <div class="row">
                <div class="col-12 d-flex flex-column align-items-center justify-content-center text-center position-relative">
                    <div class="stack" style="--stacks: 3;">
                        <span style="--index: 0;">Tech that welcomes everyone</span>
                        <span style="--index: 1;">Tech that welcomes everyone</span>
                        <span style="--index: 2;">Tech that welcomes everyone</span>
                    </div>
                    <img class="card-img-top img-fluid" src="../assets/capib.png" alt="">
                </div>
            </div>
        </div>
    </section>
</template>

<script>


    export default {
        name: 'initialSection',
    }
</script>