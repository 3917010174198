<template>
  <section>
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12 mt-5 text-center">
          <h2 class="subTitle">Servicios</h2>
        </div>
        <div class="col-lg-12 col-md-12 mb-5 text-center">
            <p class="capibDesc">Ofrecemos soluciones digitales personalizadas para impulsar tu negocio en la era tecnológica. Desde diseño web y marketing digital hasta blockchain, inteligencia artificial e inteligencia de negocios, te ayudamos a optimizar procesos, mejorar tu presencia online y maximizar resultados.</p>
          </div>
        <div
          class="col-lg-6 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/web.png" width="180" />
              <h5 class="card-title text-dark red">Diseño Web Profesional y Optimizado</h5>
              <p class="capibDesc text-left">
                Creamos sitios web modernos, rápidos y optimizados para SEO y conversión. Diseñamos páginas atractivas, funcionales y adaptadas a móviles y desktop, garantizando la mejor experiencia para tus usuarios. Potencia tu negocio con un sitio web que genera tráfico, clientes y ventas. <br> <br>

              💡 Diseño único | 🔍 SEO avanzado | ⚡️ Carga rápida | 📱 Responsive <br>
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card text-center card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body mt-3">
              <img src="../assets/business.png" width="180" />
              <h5 class="card-title red">Inteligencia de Negocios: Toma Decisiones con Datos Reales</h5>
              <p class="capibDesc text-left">
                Transformamos datos en estrategias inteligentes para hacer crecer tu negocio. Implementamos herramientas avanzadas de análisis, reportes y dashboards que te permiten visualizar oportunidades, optimizar procesos y mejorar la toma de decisiones. <br> <br>

                🔍 Análisis en tiempo real | 📈 Reportes personalizados | 🎯 Estrategias basadas en datos
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/mkt.png" width="180" />
              <h5 class="card-title red">Soluciones de Marketing Digital: Atrae, Convierte y Fideliza</h5>
              <p class="capibDesc text-left">
                Estrategias de marketing digital personalizadas para aumentar tu visibilidad, atraer clientes y maximizar tus ventas. Desde SEO y publicidad digital hasta gestión de redes sociales y automatización, te ayudamos a posicionar tu marca en el mundo digital. <br> <br>

                🔍 SEO estratégico | 🎯 Publicidad efectiva | 📱 Redes sociales | 📊 Data-driven marketing
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/block.png" width="180" />
              <h5 class="card-title text-dark red">Consultoría Blockchain: Innovación y Seguridad para tu Negocio</h5>
              <p class="capibDesc text-left">
                Te ayudamos a aprovechar el poder de la tecnología blockchain para mejorar la seguridad, transparencia y eficiencia de tus procesos. Desde desarrollo de contratos inteligentes hasta integración de blockchain en tu empresa, te guiamos en cada paso para que aproveches al máximo esta tecnología. <br> <br>

              ⚡️ Smart contracts | 🔐 Seguridad avanzada | 🌍 Soluciones descentralizadas
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/more.png" width="180" />
              <h5 class="card-title red">Consultoría en Inteligencia Artificial: Automatiza y Optimiza</h5>
              <p class="capibDesc text-left">
                Te ayudamos a integrar soluciones de inteligencia artificial (IA) en tu negocio para mejorar la eficiencia, personalizar la experiencia del cliente y automatizar procesos. Desde chatbots y análisis predictivo hasta automatización con machine learning, diseñamos estrategias a la medida de tus necesidades. <br> <br>

                ⚡️ IA personalizada | 🔍 Análisis predictivo | 🤖 Chatbots inteligentes | 🚀 Optimización de procesos
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 d-flex align-items-stretch text-left mt-5"
        >
          <div
            class="card card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body text-center mt-3">
              <img src="../assets/fin.png" width="180" />
              <h5 class="card-title red">Conferencias, Talleres y Congresos: Aprende de los Expertos</h5>
              <p class="capibDesc text-left">
                Compartimos nuestro conocimiento en tecnología, desarrollo web, inteligencia artificial, blockchain y marketing digital a través de conferencias, talleres y congresos. Capacita a tu equipo, mantente a la vanguardia y potencia tu negocio con expertos en innovación. <br> <br>

                📢 Conferencias especializadas | 🎓 Talleres prácticos | 🌎 Congresos tecnológicos
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "aboutSection",
};
</script>
