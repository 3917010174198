<template>
  <!-- <section class="capibSection" :style="{'background-image': 'url(' + require('../assets/me.png') + ')'}"> -->
  <section class="capibSection">
    <div class="container mt-5 mb-5">
      <div class="row d-flex flex-row-reverse">
        <div class="col-lg-12 col-md-12 mt-5 mb-5 text-center">
          <h2 class="subTitle">Contacto: <span>¡Estamos Aquí para Ayudarte!</span></h2>
          <p class="capibDesc">
            En The Capibara Web, estamos comprometidos con el éxito de tu negocio y queremos ofrecerte las mejores
            soluciones digitales. Si tienes preguntas, inquietudes o estás listo para llevar tu proyecto al siguiente
            nivel, no dudes en ponerte en contacto con nosotros. Nuestro equipo estará encantado de ayudarte a encontrar
            la solución perfecta para ti.
          </p>
          <p class="capibDesc">
            📍 Ubicación <br>
            Durango, México <br> <br>

            📱 Teléfono <br>
            <a href="tel:+526181835957"> +52 618 183 5957</a><br> <br>

            ✨ ¡Contáctanos hoy mismo y transformemos tu visión en una realidad digital! 🚀
          </p>
        </div>

        <div class="col-lg-12 col-md-12 text-center">
          <span>
            <a class="nav-item mr-4" href="https://x.com/cryptomexicano" target="_blank"><i
                class="fab fa-twitter"></i></a>
            <a class="nav-item " href="https://wa.link/hgcjxy" target="_blank"><i class="fab fa-whatsapp"></i></a>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "aboutSection",
  };
</script>