<template>
    <section class="section">
        <div class="stars">
          <div
            v-for="(star, index) in stars"
            :key="index"
            class="star"
            :style="{
              width: star.size + 'px',
              height: star.size + 'px',
              top: star.y + 'px',
              left: star.x + 'px',
              animationDuration: star.duration + 's'
            }"
          >
          ⚡
        </div>
        </div>
    </section>
</template>

<script>
export default {
  data() {
    return {
      stars: []
    };
  },
  mounted() {
    this.generateStars(150);
  },
  methods: {
    generateStars(count) {
      for (let i = 0; i < count; i++) {
        this.stars.push({
          size: Math.random() * 3 + 1, // Tamaño aleatorio entre 1px y 4px
          x: Math.random() * window.innerWidth,
          y: Math.random() * window.innerHeight,
          duration: Math.random() * 2 + 1 // Animación entre 1s y 3s
        });
      }
    }
  }
};
</script>

<style scoped>

.stars {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden !important;
  pointer-events: none;
}

.star {
  position: absolute;
  background: white;
  border-radius: 50%;
  animation: twinkle ease-in-out infinite alternate;
}

@keyframes twinkle {
  0% {
    opacity: 0.3;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.3);
  }
}
</style>
