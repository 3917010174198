<template>
  <!-- <section class="capibSection" :style="{'background-image': 'url(' + require('../assets/me.png') + ')'}"> -->
  <section class="capibSection">
    <div class="container mt-5 mb-5">
      <div class="row d-flex flex-row-reverse">
        <div class="col-lg-12 col-md-12 mt-5 mb-5 text-center">
          <h2 class="subTitle">Acerca <span>de</span></h2>
        </div>
      </div>
      <div class="row d-flex flex-row-center">
        <div class="col-lg-6 col-md-6 text-left">
          <div
            class="card turnRight card-custom"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
          >
            <div class="card-body m-5">
              <h5>Innovación y Tecnología a tu Alcance</h5>
              <p class="capibDesc">
                En The Capibara Web, combinamos creatividad, innovación y tecnología para ofrecer soluciones digitales de alto impacto. Fundada en 2017 en Durango, México, nuestra empresa ha evolucionado para convertirse en un referente en el desarrollo de sitios web, sistemas, aplicaciones, consultoría web, blockchain y soluciones basadas en inteligencia artificial (IA).
              </p>
              <p class="capibDesc">
                <strong>Nuestra Historia</strong> <br>
                Nuestra Historia
                Nuestro fundador, Lorenzo Arellano, es un apasionado desarrollador de software y emprendedor con una visión clara: ayudar a empresas y emprendedores a aprovechar el poder de la tecnología para crecer en el mundo digital. Con una mentalidad enfocada en la vanguardia tecnológica, The Capibara Web nació con el propósito de ofrecer soluciones digitales personalizadas que impulsen el éxito de nuestros clientes.
              </p>
              <p class="capibDesc">
                <strong>¿Qué nos hace diferentes?</strong> <br>
                🚀 Innovación constante – Nos mantenemos a la vanguardia de las tendencias tecnológicas para ofrecerte soluciones de última generación. <br>
                🎯 Estrategias personalizadas – No ofrecemos soluciones genéricas. Analizamos cada negocio para desarrollar estrategias a medida.<br>
                🔍 SEO y marketing digital – Creamos sitios web optimizados para buscadores, garantizando mayor visibilidad y tráfico orgánico.<br>
                🤝 Acompañamiento y consultoría – Más que proveedores, nos convertimos en socios estratégicos para el crecimiento de tu negocio.
              </p>

            </div>
          </div>
        </div>

        <div
          class="col-lg-6 col-md-6 d-flex order-first align-items-center text-left"
        >
          <img
            class="card-img-top img-fluid"
            v-tilt="{
              reverse: true,
              transition: true,
              speed: 500,
              perspective: 1200,
              easing: 'cubic-bezier(0.03,0.98,0.52,0.99)',
            }"
            src="../assets/about.webp"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "aboutSection",
};
</script>
