<template>
  <section>
    <div class="container mt-5 mb-5">
      <div class="row">
        <div class="col-lg-12 col-md-12 mt-5 text-center">
          <h2 class="subTitle">Nuestros Clientes: Innovadores que Transforman el Futuro</h2>
        </div>
        <div class="col-lg-12 col-md-12 mb-5 text-center">
          <p class="capibDesc">En The Capibara Web, trabajamos con empresas, emprendedores y organizaciones que buscan
            potenciar su crecimiento a través de la tecnología. Desde negocios emergentes hasta grandes corporaciones,
            nuestros clientes confían en nosotros para desarrollar soluciones digitales personalizadas, seguras y
            escalables.</p> <br>
        </div>
        <div class="col-lg-12 col-md-12 mb-5 text-center">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/fsto.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    FSTO UK, una empresa con sede en el Reino Unido, confió en The Capibara Web para el desarrollo de su
                    sitio web, logrando una plataforma moderna, funcional y optimizada para su mercado. Diseñamos un
                    sitio rápido, seguro y adaptado a dispositivos móviles, asegurando una excelente experiencia de
                    usuario y un sólido posicionamiento en buscadores. <br> <br>
                    <strong>🔹 Lo que hicimos por FSTO UK: <br> <br></strong>
                    ✅ Diseño web personalizado con una interfaz intuitiva y profesional. <br>
                    ✅ Adaptabilidad móvil para una navegación fluida en cualquier dispositivo.<br>
                    ✅ Seguridad y rendimiento mejorado para garantizar confianza y estabilidad.<br>
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/digi.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    DigitalSelf, una innovadora empresa del Reino Unido, confió en The Capibara Web para desarrollar un
                    sitio web único, inspirado en el diseño de una tabla periódica de elementos químicos. Creamos una
                    experiencia interactiva y visualmente impactante que refleja la esencia de la empresa y facilita la
                    navegación intuitiva de su contenido.<br> <br>
                    <strong>🔹 Lo que hicimos por DigitalSelf: <br> <br></strong>
                    ✅ Diseño web innovador, basado en una tabla periódica interactiva. <br>
                    ✅ Experiencia de usuario intuitiva y atractiva. <br>
                    ✅ Optimización para rendimiento y SEO, mejorando su visibilidad online. <br>
                    ✅ Adaptabilidad móvil y velocidad optimizada para una navegación fluida.
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/agt.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    Agtronix, una empresa ubicada en Florida, EE. UU., confía actualmente en The Capibara Web para
                    desarrollar un
                    ecosistema tecnológico integral que abarca desde sitios web hasta sistemas avanzados de irrigación,
                    APIs, aplicaciones de escritorio y automatizaciones de lectura de clima. Este innovador conjunto de
                    herramientas también incluye robots de Telegram para facilitar la comunicación en tiempo real.<br>
                    <br>
                    <strong>🔹 Lo que <strong>hacemos</strong> por Agtronix: <br> <br></strong>
                    ✅ Desarrollo de sitios web optimizados para la industria agrícola. <br>
                    ✅ Sistemas de irrigación automatizados para mejorar la eficiencia en el uso del agua.<br>
                    ✅ APIs personalizadas para integrar datos en tiempo real.<br>
                    ✅ Aplicaciones de escritorio.<br>
                    ✅ Automatización de lectura de clima para tomar decisiones informadas.<br>
                    ✅ Bots de Telegram para comunicación y alertas en tiempo real.<br>
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/ecn.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    Education Canada Network (ECN), uno de nuestros clientes más importantes, confió en The Capibara Web
                    para el desarrollo, mantenimiento y cobertura de marketing de su sitio web, dedicado a ofertas de
                    empleo en el sector educativo en Canadá. Nuestro enfoque integral ha optimizado la plataforma,
                    mejorado su visibilidad online y garantizado una experiencia de usuario fluida y efectiva para
                    conectar a profesionales de la educación con las mejores oportunidades laborales.<br> <br>
                    <strong>🔹 Lo que <strong>hacemos</strong> por ECN: <br> <br></strong>
                    ✅ Desarrollo web personalizado, con un diseño intuitivo y fácil de usar. <br>
                    ✅ Optimización SEO para aumentar la visibilidad de ofertas educativas.<br>
                    ✅ Mantenimiento constante para asegurar el rendimiento y la seguridad del sitio.<br>
                    ✅ Estrategias de marketing digital para atraer tanto a empleadores como a candidatos.
                  </p>
                </div>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/ean.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    Education America Network (EAN) es nuestro socio estratégico y cofundador en un proyecto que ha
                    revolucionado la forma en que los profesionales de la educación encuentran oportunidades laborales
                    en Estados Unidos. Siguiendo el éxito de Education Canada Network (ECN), en The Capibara Web hemos
                    aportado nuestra experiencia en desarrollo web, marketing digital y mantenimiento de plataformas
                    para crear un sitio dedicado a ofertas de empleo en el sector educativo a lo largo de todo EE.
                    UU.<br> <br>
                    <strong>🔹 Lo que <strong>hacemos</strong> por EAN: <br> <br></strong>
                    ✅ Desarrollo web avanzado, con un diseño adaptado a las necesidades de los profesionales de la
                    educación en EE. UU. <br>
                    ✅ Optimización SEO para posicionar ofertas de empleo de forma destacada en buscadores.<br>
                    ✅ Mantenimiento continuo para asegurar una experiencia de usuario sin interrupciones.<br>
                    ✅ Estrategias de marketing digital para atraer tanto a empleadores como a candidatos educativos.
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/jfairs.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    Jfairs, una destacada empresa de Canadá, se especializa en la organización de ferias de empleo
                    virtuales para conectar a empleadores con candidatos a nivel global. En The Capibara Web, hemos
                    trabajado de cerca con Jfairs para realizar mantenimiento web, optimización SEO, organización de las
                    ferias y brindar soporte en tiempo real durante los eventos. Nuestra colaboración garantiza que cada
                    feria sea un éxito rotundo, con plataformas rápidas, seguras y siempre disponibles para los
                    participantes.<br> <br>
                    <strong>🔹 Lo que <strong>hacemos</strong> por JFairs: <br> <br></strong>
                    ✅ Mantenimiento web continuo, asegurando el buen funcionamiento de la plataforma antes, durante y
                    después de cada feria. <br>
                    ✅ Optimización SEO para mejorar la visibilidad de las ferias y atraer a más candidatos y
                    empleadores.<br>
                    ✅ Organización y coordinación de ferias virtuales, creando una experiencia fluida y eficiente.<br>
                    ✅ Soporte en tiempo real durante las ferias de empleo para resolver cualquier duda o problema que
                    surja.
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/cc.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    Centro de Convenciones Holiday Inn, ubicado en Durango, México, confió en The Capibara Web para el
                    desarrollo de su sitio web y la optimización SEO de su plataforma. Nuestro trabajo se centra en
                    garantizar que el sitio web del centro de convenciones sea una herramienta eficaz para atraer más
                    eventos, visitantes y clientes. La optimización SEO asegura que el sitio sea fácilmente encontrado
                    en los motores de búsqueda, mejorando su visibilidad y alcance online.<br> <br>
                    <strong>🔹 Lo que <strong>hacemos</strong> por Centro de Convenciones Durango: <br> <br></strong>
                    ✅ Desarrollo web con diseño intuitivo, atractivo y funcional. <br>
                    ✅ Optimización SEO para mejorar la visibilidad en Google y atraer más clientes potenciales. <br>
                    ✅ Mantenimiento constante del sitio para asegurar su rendimiento y actualización.
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/rsco.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    Real Silver Coin, una empresa con presencia en México y Anguila, confió en The Capibara Web para el
                    desarrollo web y de aplicaciones con un enfoque especializado en blockchain. Creando soluciones
                    innovadoras, hemos desarrollado plataformas seguras y eficientes para el manejo de inversiones
                    respaldadas por plata, permitiendo a los usuarios comprar y gestionar sus activos digitales de
                    manera fácil y transparente.<br> <br>
                    <strong>🔹 Lo que <strong>hacemos</strong> por Real Silver: <br> <br></strong>
                    ✅ Desarrollo web y aplicaciones personalizadas con tecnología blockchain para una mayor seguridad y
                    transparencia. <br>
                    ✅ Implementación de sistemas de pago y gestión de activos digitales. <br>
                    ✅ Optimización de plataformas para una experiencia de usuario fluida. <br>
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/icd.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    Icedium fue una empresa internacional con presencia en Estados Unidos, Reino Unido, Canadá y Europa,
                    especializada en soluciones basadas en blockchain. En The Capibara Web, desempeñamos un papel
                    fundamental como co-fundadores y proveedores de servicios web, brindando soluciones digitales
                    innovadoras que aprovecharon el potencial de blockchain para transformar la forma en que las
                    empresas gestionan sus procesos y transacciones.<br> <br>
                    <strong>🔹 Lo que <strong>hicimos</strong> por Icedium: <br> <br></strong>
                    ✅ Desarrollo de plataformas web y aplicaciones enfocadas en blockchain, con seguridad y
                    transparencia. <br>
                    ✅ Implementación de soluciones descentralizadas para mejorar la eficiencia y la confianza en las
                    transacciones. <br>
                    ✅ Soporte técnico y mantenimiento continuo para garantizar el rendimiento óptimo de la plataforma.
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/hp.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    HummingPay POS, un innovador proyecto del Grupo ICEDIUM, es una solución de pago descentralizada
                    basada en la blockchain Tron. Con presencia en Estados Unidos, Reino Unido, Canadá y Europa,
                    HummingPay POS ofrece una forma segura y eficiente de aceptar pagos para particulares y empresas.
                    Gracias a su integración con blockchain, permite transacciones rápidas, transparentes y sin
                    intermediarios, garantizando una experiencia de pago sin igual.<br> <br>
                    <strong>🔹 Lo que <strong>hicimos</strong> por HummingPay: <br> <br></strong>
                    ✅ Desarrollo web y aplicaciones para integrar la solución de pago descentralizada. <br>
                    ✅ Implementación de tecnologías blockchain para mejorar la seguridad de las transacciones. <br>
                    ✅ Soporte en la integración de pagos para empresas y particulares, adaptándonos a las necesidades de
                    cada cliente.
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/tr.png" class="img-fluid" alt="" width="180" />
                  <p class="capibDesc text-left">
                    Team Tronics es uno de los primeros 27 #TRON SRs electos, un movimiento impulsado por la comunidad
                    de TRON, para la comunidad, con el objetivo de acelerar el crecimiento de la @tronfoundation. En The
                    Capibara Web, jugamos un papel crucial como co-fundadores y desarrolladores del sitio web de Team
                    Tronics, creando una plataforma robusta y eficiente que facilita la participación y expansión dentro
                    de la red TRON.<br> <br>
                    <strong>🔹 Lo que <strong>hicimos</strong> por Team Tronics: <br> <br></strong>
                    ✅ Desarrollo web personalizado para reflejar la misión y visión de Team Tronics. <br>
                    ✅ Optimización de la plataforma para la interacción de la comunidad y la gestión de votos. <br>
                    ✅ Soporte continuo para asegurar el funcionamiento ideal del sitio.
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="card card-custom2">
                <div class="card-body text-center mt-3">
                  <img src="../assets/cy.png" class="img-fluid" alt="" width="220" />
                  <p class="capibDesc text-left">
                    CyberTayoltita, uno de nuestros clientes más importantes en San Dimas y Durango, Durango, ha
                    confiado en The Capibara Web para el desarrollo de aplicaciones web especializadas en el envío de
                    divisas, con la implementación de robots automatizados de WhatsApp para mejorar la comunicación y la
                    eficiencia en las transacciones. Además, hemos trabajado en la creación de soluciones en la nube y
                    proporcionado servicios de ciberseguridad para proteger las operaciones de nuestros clientes y
                    usuarios.<br> <br>

                    <strong>🔹 Lo que <strong>hacemos</strong> por CyberTayoltita: <br> <br></strong>
                    ✅ Desarrollo de aplicaciones web personalizadas para el envío de divisas de manera segura y
                    eficiente. <br>
                    ✅ Implementación de robots automatizados de WhatsApp, mejorando la experiencia de usuario y
                    acelerando el proceso de envío. <br>
                    ✅ Soluciones en la nube para un acceso más ágil y seguro a los servicios. <br>
                    ✅ Ciberseguridad avanzada para proteger las transacciones y datos sensibles de los usuarios.
                  </p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import "swiper/css/swiper.css";
  export default {
    name: "portfolio",
    data() {
      return {
        swiperOption: {
          slidesPerView: 1,
          spaceBetween: 0,
          autoplay: {
            delay: 6600,
            disableOnInteraction: false,
          },
          breakpoints: {
            1024: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          },
        },
      };
    },
    components: {
      Swiper,
      SwiperSlide,
    },
  };
</script>

<style>
  .el-carousel__item h3 {
    color: #1a1a1a;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #1a1a1a;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #1a1a1a;
  }
</style>