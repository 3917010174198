<template>
  <div>
    <StarsBackground />
    <Navbar />
    <initialSection />
    <aboutSection />
    <servicesSection />
    <separator2/>
    <portfolioSection />
    <contactSection />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import initialSection from "@/components/initialSection.vue";
import aboutSection from "@/components/aboutSection.vue";
import servicesSection from "@/components/servicesSection.vue";
import portfolioSection from "@/components/portfolio.vue";
import contactSection from "@/components/contact.vue";
import StarsBackground from "@/components/StarsBackground.vue";
import separator2 from "@/components/separator2.vue";
export default {
  name: "Index",
  components: {
    Navbar,
    initialSection,
    aboutSection,
    servicesSection,
    portfolioSection,
    contactSection,
    StarsBackground,
    separator2
  },
  data() {
    return {};
  },
};
</script>
