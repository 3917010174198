<template>
        <nav class="navbar navbar-expand-lg justify-content-center">
            <span  class="navbar-brand mr-0">
                <img src="../assets/logo.webp" alt="" width="300">
            </span>
            <!-- <button class="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#collapsingNavbar2">
                <span class="navbar-toggler-icon">-</span>
            </button>
            <div class="navbar-collapse collapse justify-content-between " id="collapsingNavbar2">
                <ul class="navbar-nav mx-auto text-center">
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Inicio</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Acerca de</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Servcios</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Portafolio</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Contacto</a>
                    </li>

                </ul>
            </div> -->
        </nav>
</template>

<script>
    
    export default {
        name: 'Navbar',
    }
</script>

<style lang="css">
    .navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent !important;
    border: 1px solid #d03352 !important;
    border-radius: .25rem;
}

.navbar-toggler-icon {
    color: #d03352; /* Cambia #ff0000 al color que desees */
}
</style>